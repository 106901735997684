import React from "react";
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import { TbBrandOnlyfans } from "react-icons/tb";
import logo from "../assets/images/logo.jpeg";

export const Footer = () => {
  return (
    <>
      <footer>
        <section className="py-4" style={{ backgroundColor: "#000" }}>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 d-flex align-items-center">
              <div className="d-flex col">
                <a href="/" className="mb-3 mb-md-0">
                  <img src={logo} width="25%" alt="logo" />
                </a>
              </div>

              <ul className="nav d-flex col list-unstyled justify-content-start justify-content-md-center">
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#about" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#events" className="nav-link">
                    Events
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#gallery" className="nav-link">
                    Gallery
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#shop" className="nav-link">
                    Shop
                  </a>
                </li>
              </ul>

              <ul className="d-flex col justify-content-start justify-content-md-center list-unstyled">
                <li className="me-3">
                  <a href="https://twitter.com/glowwitdaflow/">
                    <FaTwitter style={{ fontSize: "1.5rem", color: "white" }} />
                  </a>
                </li>
                <li className="me-3">
                  <a href="https://www.facebook.com/glowwitdaflow/">
                    <FaFacebook
                      style={{ fontSize: "1.5rem", color: "white" }}
                    />
                  </a>
                </li>
                <li className="me-3">
                  <a href="https://instagram.com/glowwitdaflow">
                    <FaInstagram
                      style={{ fontSize: "1.5rem", color: "white" }}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://onlyfans.com/glowwitdaflow">
                    <TbBrandOnlyfans
                      style={{ fontSize: "1.5rem", color: "white" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};
