import React from "react";
import app1 from "../assets/images/about_img.jpg";
import app2 from "../assets/images/about_img.jpg";
import app3 from "../assets/images/about_img.jpg";
import app4 from "../assets/images/about_img.jpg";
import app5 from "../assets/images/about_img.jpg";

export const Events = () => {
  return (
    <>
      <section className="py-5" id="events">
        <div className="container mb-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="mb-4">Our Events</h2>
              <p>
                We host monthly events that are open to the public. Each event
                is designed to provide a unique and immersive experience that
                combines meditation, movement, and nature.
              </p>
              <p>
                On this page, you will find information about our upcoming
                events, including dates, times, and ticket prices. You can also
                learn more about our event offerings: including our energy
                healing playdays, sound healing sessions, plant based feasts,
                and auric energy consultations.
              </p>
              <p>
                We believe that everyone deserves to experience the healing
                power of music, movement, and touch, and we strive to create a
                welcoming and inclusive environment where people from all
                backgrounds can come together to heal and grow.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <div className="owl-carousel owl-theme event-carousel">
                <div className="item">
                  <img className="img-fluid" src={app1} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app2} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app3} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app4} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app5} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
