import React from "react";

export const Shop = () => {
  return (
    <>
      <section id="shop" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <h2>Shop</h2>
              <p className="lead description">
                Our Energy Healing Events offer a wide range of products that
                promote health and wellness. On this page, you will find links
                where you can browse and purchase our life-giving products.
              </p>
              <p className="lead description">
                From herbal teas, tinctures, and tonics, to essential oils and
                healing balms; we offer a wide variety of products that can
                support your journey in health and wellness. We believe that
                these products will enhance your overall experience at our
                events and support your ongoing health and wellness adventure.
              </p>
              <a href="/" target="_blank" rel="noreferrer">
                <button className="btn btn-lg">Go to Shop</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
