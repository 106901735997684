import "../src/assets/scss/css/styles.min.css";
import { Navbar } from "./components/Navbar";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import About from "./components/About";
import Gallery from "./components/Gallery";
import { Events } from "./components/Events";
import { Shop } from "./components/Shop";
import { Contact } from "./components/Contact";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Events />
      <Gallery />
      <Shop />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
