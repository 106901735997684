import React from "react";
import header_img from "../assets/images/header_img.jpg"

const Header = () => {
  return (
    <>
      <section id="header">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-flex justify-content-center flex-column">
              <p className="subtitle">Glow Wit Da Flow</p>
              <h1>Welcome to Glow Wit Da Flow!</h1>
              <p className="lead description">
                We host energy healing events designed to evolve your
                consciousness and provide you with plenty of opportunities for
                rejuvenation, so you can be super charged and ready for the
                world. Come join our unique energetic paradise and bathe in the
                blissful fun of our loving community. We offer a one-of-a-kind
                experience that combines music, dance, massages, and nature to
                create various vibes in a peaceful atmosphere that leaves you
                feeling refreshed and energized.
              </p>
              {/* <p className="lead description">
                Our event is open to everyone, regardless of your background or
                experience with energy healing. We believe that everyone
                deserves to experience this healing power.
              </p>
              <p className="lead description">
                On this page, you will find information about our upcoming
                events, as well as links to our other pages, where you can learn
                more about our mission, team, and offerings.
              </p>
              <p className="lead description">
                Thank you for visiting, and we look forward to seeing you at our
                next event!
              </p> */}
              <div className="d-flex flex-column flex-md-row">
                <a href="https://theticketing.co/earthday">
                  <button className="btn btn-lg me-2 mb-3 mb-md-0">
                    Join Event
                  </button>
                </a>
                <a href="/">
                  <button className="btn btn-lg">Learn More</button>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <img src={header_img} width="70%" alt="header img"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
