import React from "react";
import app1 from "../assets/images/gallery1.jpg";
import app2 from "../assets/images/gallery2.jpg";
import app3 from "../assets/images/gallery3.jpg";
import app4 from "../assets/images/gallery4.jpg";
import app5 from "../assets/images/gallery5.jpg";

const Gallery = () => {
  return (
    <>
      <section className="py-5" id="gallery">
        <div className="container text-center mb-5">
          
          <h2 className="mb-4">Gallery</h2>
          <p>
            Our Energy Healing Wellness Event offers a unique and immersive
            experience that is best captured through long exposure photography
            and HD action videography. On this page, you will find a gallery of
            images and videos from our past events, showcasing the energy,
            movement, and beauty that our events inspire.
          </p>
          <p>
            We believe that these images and videos can help you get a sense of
            what our events are like and the kind of atmosphere that we strive
            to create. We hope that they inspire you to join us for our next
            event and experience the healing power for yourself.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="owl-carousel owl-theme app-carousel">
                <div className="item">
                  <img className="img-fluid" src={app1} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app2} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app3} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app4} alt="#" />
                </div>
                <div className="item">
                  <img className="img-fluid" src={app5} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
