import React from "react";
import about_img from "../assets/images/about_img.jpg";

const About = () => {
  return (
    <>
      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <img src={about_img} alt="about_img" />
            </div>
            <div className="col-sm mt-4 mt-md-0">
              
              <h2>About</h2>
              <p className="lead description">
                Our Energy Healing Events were founded with the goal of
                providing a safe space where people can come together to
                experience the healing power of music, dance, and touch. Our
                team consists of experienced healers, musicians, dancers, and
                massage therapists who are passionate about helping others
                relieve stress and achieve a state of balance and harmony.
              </p>
              <p className="lead description">
                We believe that everyone has the capacity to heal themselves,
                and our events are designed to help you tap into that innate
                healing ability. Whether you are new to energy healing or a
                seasoned practitioner, we welcome you to join us for an
                unforgettable experience.
              </p>
              <p className="lead description">
                On this page, you will learn more about our team, our mission,
                and our approach to energy healing. You can also read
                testimonials from previous attendees and find out how you can
                get involved with our community.
              </p>
              <a
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-lg">Read More</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
