import React from "react";

export const Contact = () => {
  return (
    <>
      <section className="py-5" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              
              <h2>Contact Us</h2>
              <p className="lead description">
                We would love to hear from you! Whether you have questions about
                our events, want to get involved with our community, or simply
                want to say hello, we welcome your inquiries.
              </p>
              <p className="lead description">
                On this page, you will find our contact information, including
                our email address and social media links. We encourage you to
                follow us on social media to stay up-to-date on our upcoming
                events and offerings, and to connect with our energy healing
                community.
              </p>
            </div>
            <div className="col-sm mt-4 mt-md-0">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input type="text" className="form-control" id="name" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input type="text" className="form-control" id="email" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="4"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
